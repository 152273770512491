<script>
import Layout from "../../layouts/main";
//import Home from '@/components/home/home-layout.vue';

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Home",
    },
    components: {
        Layout,
        //Home,
    },
    methods:{
        setSavedRoute(){
            //get last route from local storage
            let route = null;
            if(route != null){
                this.$router.push( {name: route} );
            }
            else{
                this.$router.push({ name: "model" });
            }
        }
    },
    data() {
        return {
            title: "Home",
            items:[],
        };
    },
        mounted() {
            this.setSavedRoute();
        },
};
</script>

<template>
    <Layout>
        <b-row>
            <b-col cols="12">
                <b-overlay :show="true">
                    <b-card style="height: 70vh;">
                    </b-card>
                </b-overlay>
            </b-col>
        </b-row>
        <!--<Home />-->
    </Layout>
</template>
